import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import * as contentful from 'contentful';
import { store } from 'src/store/store';
import {
  selectCurrentEntity,
  setCurrentEntity,
  setCurrentStep,
  setCurrentSurvey,
  setCurrentVersion,
  setErrors,
  setLoading,
  setModal,
  setSelectedCollaborator,
} from '../reducers/app.reducer';
import * as constant from './constants';
import { enhanceSurvey, selectAllContentfulData, selectSurveys } from '../reducers/contentful.reducer';
import { selectUser, selectActiveAccount, selectSelectedAccount, updateUser, updateEntityResponses } from '../reducers/account.reducer';
import * as _ from 'lodash';
import { getSignedUrlByKey, getSurveyById, uploadDocumentToS3 } from './api';
import { paths } from '@routes/routes.constants';
import { getSessionValue } from './storage';

var client = contentful.createClient({
  space: '5h3y0cofo0sv',
  accessToken: 'xnrKvKF_08E69gufbsa2JVqvNKJuJQQhwSQHkgeA8cs',
});

export const ContentTypes = {
  ARRAY: 'documentArray',
  BULLET: 'bulletPointList',
  TEXT: 'documentPart',
  TITLE: 'sectionTitle',
  COMPOSED: 'composedPart',
};

export const customFetch = async (url, method, body, xsrf, addType = true, removeError = false) => {
  let customHeaders = new Headers();
  if (addType) {
    customHeaders.set('Content-Type', 'application/json');
    customHeaders.set('accept', 'application/json');
  }

  let customOptions: any = {
    method: method,
    headers: customHeaders,
    body: body ?? undefined,
  };

  if (xsrf) {
    const tempToken = getSessionValue('tempToken');
    if (tempToken) customHeaders.set('x-temp', tempToken);
    customHeaders.set('x-xsrf-token', window.sessionStorage.getItem('user_token'));
    customOptions = {
      ...customOptions,
      headers: customHeaders,
      mode: 'cors',
      credentials: 'include',
    };
  }

  const customRequest = new Request(url, customOptions);

  const response = await fetch(customRequest);
  let parsedResponse = await response.json();
  if (!response.ok && !removeError) {
    handleError(parsedResponse);
    return;
  }
  return parsedResponse;
};

const handleError = error => {
  console.log(error);
  store.dispatch(setLoading(false));
  store.dispatch(setModal({ show: false, type: null }));
  store.dispatch(setErrors(error));
};

// **********************************************************************
// ******************   RETRIEVE FROM CONTENTFUL    *********************
// **********************************************************************

const parseBulletItems = listItems => {
  return listItems.map(item => ({
    ...item.fields,
    id: item?.sys?.id,
    type: item.sys.contentType.sys.id,
    content: item.sys.contentType.sys.id === ContentTypes.COMPOSED ? parseComposedParts(item.fields.content) : item.fields.content,
    conditions: item.fields.conditions?.conditions,
    ...(item.fields.replace && { replace: item.fields.replace.replace }),
  }));
};

const parseComposedParts = parts => {
  return parts.map(item => {
    return {
      ...item.fields,
      conditions: item.fields.conditions?.conditions,
    };
  });
};

export const parseParts = parts => {
  return parts.map(part => {
    if (!part.fields) return;

    if (part.sys.contentType.sys.id === ContentTypes.BULLET) {
      return {
        ...part.fields,
        conditions: part.fields.conditions?.conditions,
        replace: part.fields.replace?.replace,
        listItems: parseBulletItems(part.fields.listItems),
        type: part.sys.contentType.sys.id,
        id: part?.sys?.id,
      };
    }

    if (part.sys.contentType.sys.id === ContentTypes.TITLE) {
      return {
        ...part.fields,
        conditions: part.fields.conditions?.conditions,
        replace: part.fields.replace?.replace,
        type: part.sys.contentType.sys.id,
        id: part?.sys?.id,
      };
    }

    if (part.sys.contentType.sys.id === ContentTypes.COMPOSED) {
      return {
        ...part.fields,
        conditions: part.fields.conditions?.conditions,
        replace: part.fields.replace?.replace,
        content: parseComposedParts(part.fields.content),
        type: part.sys.contentType.sys.id,
        id: part?.sys?.id,
      };
    }

    if (part.sys.contentType.sys.id === ContentTypes.ARRAY) {
      return {
        ...part.fields,
        conditions: part.fields.conditions?.conditions,
        replace: part.fields.replace?.replace,
        type: part.sys.contentType.sys.id,
        rows: part.fields.rows?.map(row => {
          return {
            conditions: row.fields?.conditions?.conditions,
            values: row.fields?.values?.map(value => {
              return {
                values: value.fields?.values?.map(value => ({
                  text: value.fields?.text,
                  conditions: value.fields?.conditions?.conditions,
                })),
                composed: value.sys.contentType.sys.id === 'composedRowValue',
                text: value.fields?.text,
                replace: value.fields?.replace?.replace,
                backgroundColor: value.fields?.backgroundColor,
                conditions: value.fields?.conditions?.conditions,
              };
            }),
          };
        }),
        id: part?.sys?.id,
      };
    }

    return {
      ...part.fields,
      conditions: part?.fields?.conditions?.conditions,
      replace: part?.fields?.replace?.replace,
      type: part?.sys?.contentType?.sys.id,
      id: part?.sys?.id,
    };
  });
};

export const parseRichConditions = conditions => {
  return conditions.map(condition => {
    return {
      ...condition.fields,
    };
  });
};

const parseQuestions = questions =>
  questions.map(question => {
    const optionsList = question.fields.optionList;
    const richConditions = question.fields.richConditions;
    return {
      ...question.fields,
      long: question.fields.longResponses,
      conditions: question.fields?.conditions?.conditions,
      required: !!question.fields?.required,
      ...(richConditions && { richConditions: parseRichConditions(richConditions) }),
      ...(optionsList && { options: parseOptions(optionsList) }),
      id: question.sys.id,
    };
  });

const parseOptions = optionsList => {
  return optionsList.map(option => {
    if (option.fields.name && option.fields?.status.toLowerCase() === 'conforme') {
      return { label: option.fields.name, value: option.fields.name.toLowerCase(), ...option.fields };
    } else
      return {
        ...option.fields,
        name: option.fields.value,
      };
  });
};

export const parseSteps = steps =>
  steps.map(step => ({
    id: step.sys.id,
    name: step.fields.name,
    form: parseQuestions(step.fields.stepQuestions),
    conditions: step.fields?.conditions?.conditions,
  }));

export const getSurvey = async () => {
  let response = await client.getEntries({
    content_type: 'survey',
    include: 10,
  });

  return response.items
    .map((survey: any) => {
      return {
        id: survey.sys.id,
        name: survey.fields.name,
        index: survey.fields.index,
        steps: parseSteps(survey.fields.steps),
      };
    })
    .sort((a, b) => a.index - b.index);
};

export const getAuthorizedSurveys = () => {
  const state = store.getState();
  let contentfulData = selectAllContentfulData(state);
  let activeAccount = selectActiveAccount(state);
  let activeUser = selectUser(state);
  let currentEntity = selectCurrentEntity(state);

  return contentfulData.surveys?.filter(survey => {
    if (!activeAccount?.actionRights?.surveys?.some(surveyRights => survey.id === surveyRights.id)) {
      return false;
    }
    return activeUser?.role !== 'guest'
      ? true
      : activeUser?.sharedItems?.surveys?.some(
          surveyItem =>
            (surveyItem.surveyId === 'all' && surveyItem.entityId === currentEntity?._id) ||
            (surveyItem.surveyId === survey.id && surveyItem.entityId === currentEntity?._id)
        );
  });
};

export const getAuthorizedSurveysByAccount = () => {
  const state = store.getState();
  let contentfulData = selectAllContentfulData(state);
  let activeAccount = selectActiveAccount(state);
  return contentfulData.surveys?.filter(survey => {
    return activeAccount?.actionRights?.surveys?.some(surveyRights => survey.id === surveyRights.id);
  });
};

export const getAuthorizedSurveysByEntity = entity => {
  const state = store.getState();
  let contentfulData = selectAllContentfulData(state);
  let activeAccount = selectActiveAccount(state);
  let activeUser = selectUser(state);
  return contentfulData.surveys
    ?.filter(survey => activeAccount?.actionRights?.surveys?.some(surveyRights => survey.id === surveyRights.id))
    .filter(survey => {
      return activeUser?.role !== 'guest'
        ? true
        : activeUser?.sharedItems?.surveys?.some(
            surveyItem =>
              (surveyItem.surveyId === 'all' && surveyItem.entityId === entity?._id) ||
              (surveyItem.surveyId === survey.id && surveyItem.entityId === entity?._id)
          );
    });
};

export const getStepQuestions = (survey, version, stepName, entity) => {
  const state = store.getState();
  let contentfulData = selectAllContentfulData(state);
  let currentResponses = entity.responses
    .find(entityResponse => entityResponse.surveyId === survey.id)
    ?.versions.find(versionItem => versionItem.versionName === version.versionName).versionResponses;
  if (!currentResponses) {
    return {
      questions: [],
      amount: 0,
    };
  }
  let stepQuestions = [];

  contentfulData.surveys
    ?.find(ctfSurvey => ctfSurvey.id === survey?.id)
    ?.steps?.filter(step => step.name === stepName)
    .map(step => {
      return step.form.map(question => {
        if (
          currentResponses[question.name] !== undefined &&
          currentResponses[question.name] !== null &&
          !_.isEmpty(currentResponses[question.name])
        ) {
          stepQuestions.push({
            name: question.name,
            version: version.versionName,
          });
        }
      });
    });

  return {
    questions: stepQuestions,
    amount: stepQuestions.length,
  };
};

// **********************************************************************
// ******************   VALIDATIONS  ************************************
// **********************************************************************

export function isTelValid(number) {
  let telRegExp = new RegExp(/^[0-9]{10}$/g);
  return telRegExp.test(number);
}

export function isURLValid(url: string) {
  let urlRegExp = new RegExp(/^(https?:\/\/(?:www\.)?)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,20}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=,\s]*)$/g);
  return urlRegExp.test(url);
}

export function isEmailValid(email) {
  let emailRegExp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return emailRegExp.test(email);
}

export function isEmailValidRFC5322(email) {
  let emailRegExp = new RegExp(
    /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])$/
  );
  return emailRegExp.test(email);
}

// **********************************************************************
// ******************   REGISTRIES AND DOCUMENTS  *********************
// **********************************************************************

export const handleConditions = (entry, formData) => {
  if (!entry.conditions) {
    return true;
  }

  if (!formData) {
    return false;
  }

  let modifiedFormData = formData.versionResponses ?? formData;
  return entry.conditions.every(condition => {
    if (Array.isArray(modifiedFormData[condition['key']])) {
      return modifiedFormData[condition['key']].some(value => value === condition['value']);
    }
    return modifiedFormData[condition['key']] === condition['value'];
  });
};

const replaceInString = (string, replaceObj, formData) => string?.replaceAll(replaceObj['key'], formData[replaceObj['value']]);

export const handleReplace = (part, formData) => {
  if (!part.replace) {
    return part;
  }

  let copyPart = {
    ...part,
  };
  copyPart?.replace?.forEach(replaceObj => {
    let partType = copyPart.content ? 'DocPart' : copyPart.text ? 'Value' : 'Sheet';
    switch (partType) {
      case 'DocPart':
        copyPart.content = replaceInString(copyPart.content, replaceObj, formData);
        break;
      case 'Value':
        copyPart.text = replaceInString(copyPart.text, replaceObj, formData);
        break;
      case 'Sheet':
        copyPart.name = replaceInString(copyPart.name, replaceObj, formData);
        break;
    }
  });
  return copyPart;
};

export const getColorFromProcessorStatus = processorStatus => {
  return constant.PROCESSOR_MONITORING_STATUS.find(process => process.label === processorStatus)?.color ?? undefined;
};

export const getCellAddress = (rowIndex, columnIndex) => {
  let columnLetter = '';
  /* Note : Row & Column indexes start at 1,0 instead of A2. Because of headers. Thus we add 1 to column and 1 to row */
  let newColumnIndex = columnIndex + 1;
  let newRowIndex = rowIndex + 1;
  let t = 0;

  while (newColumnIndex > 0) {
    t = (newColumnIndex - 1) % 26;
    columnLetter = String.fromCharCode(65 + t) + columnLetter;
    newColumnIndex = ((newColumnIndex - t) / 26) | 0;
  }
  return columnLetter + newRowIndex;
};

export const getLinksKeyToDocs = contentfulDocuments => {
  let linksKeyToDocs = {};
  contentfulDocuments.map(doc => {
    linksKeyToDocs[doc.id] = [];
    doc?.parts?.forEach(part => {
      if (part.conditions) {
        part.conditions.forEach(condition => {
          let isPresent = linksKeyToDocs[doc.id].some(elt => elt === condition.key);
          if (!isPresent) {
            linksKeyToDocs[doc.id].push(condition.key);
          }
        });
      }
      if (part.replace) {
        part.replace.forEach(replace => {
          let isPresent = linksKeyToDocs[doc.id].some(elt => elt === replace.value);
          if (!isPresent) {
            linksKeyToDocs[doc.id].push(replace.value);
          }
        });
      }
    });
  });
  for (let keys in linksKeyToDocs) {
    if (!(linksKeyToDocs[keys].length > 0)) {
      delete linksKeyToDocs[keys];
    }
  }
  return linksKeyToDocs;
};

/*export const getAllDocDependencies = contentfulDocuments => {
  let linksKeyToDocs = {};
  contentfulDocuments.map(doc => {
    linksKeyToDocs[doc.id] = [];
    doc?.parts?.forEach(part => {
      if (part.conditions) {
        part.conditions.forEach(condition => {
          linksKeyToDocs[doc.id].push({ key: condition.key, id: part.id });
        });
      }
      if (part.replace) {
        part.replace.forEach(replace => {
          linksKeyToDocs[doc.id].push({ key: replace.value, id: part.id });
        });
      }
      part?.listItems?.map(subPart => {
        if (subPart.conditions) {
          subPart.conditions.forEach(condition => {
            linksKeyToDocs[doc.id].push({ key: condition.key, id: subPart.id });
          });
        }
        if (subPart.replace) {
          subPart.replace.forEach(replace => {
            linksKeyToDocs[doc.id].push({ key: replace.value, id: subPart.id });
          });
        }
      });
      part?.rows?.map(row => {
        if (row.conditions) {
          row.conditions.forEach(condition => {
            linksKeyToDocs[doc.id].push({ key: condition.key, id: part.id });
          });
        }
        if (row.replace) {
          row.replace.forEach(replace => {
            linksKeyToDocs[doc.id].push({ key: replace.value, id: part.id });
          });
        }
        row.values.forEach(value => {
          if (value.conditions) {
            value.conditions.forEach(condition => {
              linksKeyToDocs[doc.id].push({ key: condition.key, id: part.id });
            });
          }
          if (value.replace) {
            value.replace.forEach(replace => {
              linksKeyToDocs[doc.id].push({ key: replace.value, id: part.id });
            });
          }
        });
      });
    });
  });
  for (let keys in linksKeyToDocs) {
    if (!(linksKeyToDocs[keys].length > 0)) {
      delete linksKeyToDocs[keys];
    }
  }
  return linksKeyToDocs;
};*/

export const getAllRegistriesDependencies = contentfulRegistries => {
  let linksKeyToRegistry = {};
  let linksKeyToRegistrySheet = {};
  contentfulRegistries.map(registry => {
    linksKeyToRegistry[registry.id] = [];
    //registry
    registry?.sheets?.forEach(sheet => {
      //registry_sheet
      let alreadyExist = linksKeyToRegistrySheet.hasOwnProperty(sheet.name);
      if (!alreadyExist) {
        linksKeyToRegistrySheet[sheet.name] = [];
      }
      if (sheet.replace) {
        sheet.replace.forEach(replace => {
          linksKeyToRegistry[registry.id].push({ key: replace.value, id: registry.id });
          linksKeyToRegistrySheet[sheet.name].push({ key: replace.value, id: registry.id });
        });
      }
      sheet.rows.map(row => {
        //registry_row
        if (row.conditions) {
          row.conditions.forEach(condition => {
            linksKeyToRegistry[registry.id].push({ key: condition.key, id: row.id });
            linksKeyToRegistrySheet[sheet.name].push({ key: condition.key, id: row.id });
          });
        }
        if (row.replace) {
          row.replace.forEach(replace => {
            linksKeyToRegistry[registry.id].push({ key: replace.value, id: row.id });
            linksKeyToRegistrySheet[sheet.name].push({ key: replace.value, id: row.id });
          });
        }
        row?.values?.map(value => {
          if (value.conditions) {
            value.conditions.forEach(condition => {
              linksKeyToRegistry[registry.id].push({ key: condition.key, id: value.id });
              linksKeyToRegistrySheet[sheet.name].push({ key: condition.key, id: value.id });
            });
          }
          if (value.replace) {
            value.replace.forEach(replace => {
              linksKeyToRegistry[registry.id].push({ key: replace.value, id: value.id });
              linksKeyToRegistrySheet[sheet.name].push({ key: replace.value, id: value.id });
            });
          }
          if (value.composed) {
            value.values.forEach(composedRowValueValue => {
              if (composedRowValueValue.conditions) {
                composedRowValueValue.conditions.forEach(condition => {
                  linksKeyToRegistry[registry.id].push({ key: condition.key, id: composedRowValueValue.id });
                  linksKeyToRegistrySheet[sheet.name].push({ key: condition.key, id: composedRowValueValue.id });
                });
              }
              if (composedRowValueValue.replace) {
                composedRowValueValue.replace.forEach(replace => {
                  linksKeyToRegistry[registry.id].push({ key: replace.value, id: composedRowValueValue.id });
                  linksKeyToRegistrySheet[sheet.name].push({ key: replace.value, id: composedRowValueValue.id });
                });
              }
              if (composedRowValueValue.composed) {
                composedRowValueValue.values.forEach(level2ComposedRowValueValue => {
                  //TODO refacto replace + conditions
                  if (level2ComposedRowValueValue.conditions) {
                    level2ComposedRowValueValue.conditions.forEach(condition => {
                      linksKeyToRegistry[registry.id].push({ key: condition.key, id: level2ComposedRowValueValue.id });
                      linksKeyToRegistrySheet[sheet.name].push({ key: condition.key, id: level2ComposedRowValueValue.id });
                    });
                  }
                  if (level2ComposedRowValueValue.replace) {
                    level2ComposedRowValueValue.replace.forEach(replace => {
                      linksKeyToRegistry[registry.id].push({ key: replace.value, id: level2ComposedRowValueValue.id });
                      linksKeyToRegistrySheet[sheet.name].push({ key: replace.value, id: level2ComposedRowValueValue.id });
                    });
                  }
                });
              }
            });
          }
        });
      });
    });
  });

  for (let keys in linksKeyToRegistry) {
    if (!(linksKeyToRegistry[keys].length > 0)) {
      delete linksKeyToRegistry[keys];
    }
  }
  for (let keys in linksKeyToRegistrySheet) {
    if (!(linksKeyToRegistrySheet[keys].length > 0)) {
      delete linksKeyToRegistrySheet[keys];
    }
  }
  return { linksKeyToRegistry, linksKeyToRegistrySheet };
};

/*export const getRelationsBetweenSurveys = (contentfulSurveys, contentfulDocuments, actionRightsSurveys) => {
  if (!(contentfulSurveys?.length > 0) || !(contentfulDocuments?.length > 0)) {
    return;
  }
  let linksKeyToDocs = getLinksKeyToDocs(contentfulDocuments);

  let docToSurveys = {};
  let keysToSurveys = {};
  Object.keys(linksKeyToDocs).forEach(docId => {
    docToSurveys[docId] = [];
    contentfulSurveys
      .filter(survey => actionRightsSurveys?.some(userSurvey => userSurvey.id === survey.id))
      .forEach(survey => {
        keysToSurveys[survey.id] = [];
        docToSurveys[survey.id] = [];
        survey.steps.forEach(step => {
          step.form.forEach(question => {
            keysToSurveys[survey.id].push(question.name);
            if (linksKeyToDocs[docId].some(key => key === question.name)) {
              if (!docToSurveys[docId].some(doc => doc === survey.id)) {
                docToSurveys[docId].push(survey.id);
              }
            }
          });
        });
      });
  });
  for (let keys in docToSurveys) {
    if (!(docToSurveys[keys].length > 0)) {
      delete docToSurveys[keys];
    }
  }
  return { docToSurveys, keysToSurveys };
};*/

export const initEntity = entity => {
  let newResponses = [...entity.responses];
  let authorizedSurveys = getAuthorizedSurveys();
  authorizedSurveys?.forEach(survey => {
    if (!newResponses.some(entityResponses => entityResponses.surveyId === survey.id)) {
      newResponses.push({
        surveyId: survey.id,
        versions: [
          ...(!survey.versioning.enabled
            ? [
                {
                  versionName: survey.name,
                  creationDate: new Date().toISOString(),
                  versionResponses: {},
                },
              ]
            : []),
        ],
      });
    }
  });

  return {
    ...entity,
    inited: true,
    responses: newResponses,
  };
};

export const setSessionUser = user => {
  window.sessionStorage.setItem('dui', JSON.stringify(user));
};

export const getSessionUserId = () => {
  return window.sessionStorage.getItem('dui');
};

export const setSessionToken = token => {
  window.sessionStorage.setItem('user_token', token);
};

export const getSessionToken = () => {
  return window.sessionStorage.getItem('user_token');
};

export const removeSharedItem = (userId, shareId) => {
  let account = selectActiveAccount(store.getState());
  let accountCopy = _.cloneDeep(account);
  let user = accountCopy.users.find(user => {
    return user._id === userId;
  });
  for (let shareType in user.sharedItems) {
    if (shareType === '_id') continue;
    user.sharedItems[shareType] = user.sharedItems[shareType].filter(share => share.shareId !== shareId); // In case of actions remove user from responsible list
    if (shareType === 'actions' && user.sharedItems[shareType].length > 0) {
      const entityId = shareId.includes('/') ? shareId.split('/')[1] : shareId.split('-')[1];
      const relatedProductId = shareId.includes('/') ? shareId.split('/')[3] : shareId.split('-')[3];
      const actionId = shareId.includes('/') ? shareId.split('/')[5] : shareId.split('-')[5];
      const versionProductId = shareId.includes('/') ? shareId.split('/')[7] : shareId.split('-')[7];
      let entity = accountCopy.entities.find(entity => entity._id === entityId);
      let action =
        relatedProductId !== 'undefined'
          ? entity.actionPlan.actions.find(
              action => action.id === actionId && action.relatedProductId === relatedProductId && action.versionProductId === versionProductId
            )
          : entity.actionPlan.actions.find(action => action.id === actionId && action.versionProductId === versionProductId);
      if (action) action.responsible = action?.responsible.filter(responsible => responsible?.id !== userId);
    }
  }
  store.dispatch(setSelectedCollaborator(user));
  store.dispatch(updateUser({ userId: user._id, data: user }));
};

export const getActionByShareId = (account, shareId) => {
  const entityId = shareId.includes('/') ? shareId.split('/')[1] : shareId.split('-')[1];
  const relatedProductId = shareId.includes('/') ? shareId.split('/')[3] : shareId.split('-')[3];
  const actionId = shareId.includes('/') ? shareId.split('/')[5] : shareId.split('-')[5];
  const versionProductId = shareId.includes('/') ? shareId.split('/')[7] : shareId.split('-')[7];
  let entity = account.entities.find(entity => entity._id === entityId);
  let action =
    relatedProductId !== 'undefined'
      ? entity.actionPlan.actions.find(
          action => action.id === actionId && action.relatedProductId === relatedProductId && action.versionProductId === versionProductId
        )
      : entity.actionPlan.actions.find(action => action.id === actionId && action.versionProductId === versionProductId);
  return action;
};

export const getShareIdType = shareId => {
  if (shareId.includes('action')) {
    return 'actions';
  } else if (shareId.includes('question')) {
    return 'questions';
  } else if (shareId.includes('step')) {
    return 'surveySteps';
  } else if (shareId.includes('version')) {
    return 'surveyVersions';
  } else if (shareId.includes('entity')) {
    return 'entities';
  }
};

export const getSharedItemType = sharedItem => {
  if (sharedItem.shareId.includes('action')) {
    return 'actions';
  } else if (sharedItem.shareId.includes('question')) {
    return 'questions';
  } else if (sharedItem.shareId.includes('step')) {
    return 'surveySteps';
  } else if (sharedItem.shareId.includes('version')) {
    return 'surveyVersions';
  } else if (sharedItem.shareId.includes('entity')) {
    return 'entities';
  }
};

export const getSharedItemLink = async sharedItem => {
  let account = selectActiveAccount(store.getState());
  let sharedItemType = getSharedItemType(sharedItem);
  let authorizedSurveys = getAuthorizedSurveys();
  let sharedEntity = account.entities.find(entity => entity._id === sharedItem.entityId);
  let detailedSurvey = await getDetailedSurveyById(sharedItem.surveyId);
  let detailedVersion = getSurveyVersionById(sharedEntity, sharedItem.surveyId, sharedItem.surveyVersionId);
  let detailedStep = detailedSurvey?.steps.find(step => step.id === sharedItem.surveyStepId);
  store.dispatch(setCurrentEntity(sharedEntity ?? account.entities[0]));
  switch (sharedItemType) {
    case 'entities':
      store.dispatch(setCurrentSurvey(authorizedSurveys?.[0]));
      return paths.dashboardGroup;
    case 'surveyVersions':
      store.dispatch(setCurrentSurvey(detailedSurvey));
      store.dispatch(setCurrentVersion(detailedVersion));
      store.dispatch(setCurrentStep({ id: detailedSurvey.steps[0].id, name: detailedSurvey.steps[0].name }));
      return paths.survey;
    case 'surveySteps':
      store.dispatch(setCurrentSurvey(detailedSurvey));
      store.dispatch(setCurrentVersion(detailedVersion));
      store.dispatch(setCurrentStep({ id: detailedStep.id, name: detailedStep.name }));
      return paths.survey;
    case 'questions':
      store.dispatch(setCurrentSurvey(detailedSurvey));
      store.dispatch(setCurrentVersion(detailedVersion));
      store.dispatch(setCurrentStep({ id: detailedStep.id, name: detailedStep.name }));
      return paths.survey;
    case 'actions':
      const entityId = sharedItem.shareId.includes('/') ? sharedItem.shareId.split('/')[1] : sharedItem.shareId.split('-')[1];
      const relatedProductId = sharedItem.shareId.includes('/') ? sharedItem.shareId.split('/')[3] : sharedItem.shareId.split('-')[3];
      const actionId = sharedItem.shareId.includes('/') ? sharedItem.shareId.split('/')[5] : sharedItem.shareId.split('-')[5];
      const versionProductId = sharedItem.shareId.includes('/') ? sharedItem.shareId.split('/')[7] : sharedItem.shareId.split('-')[7];
      return `/plan-action/${actionId}|${relatedProductId}|${entityId}|${versionProductId}`;
  }
};

export const groupSharedQuestionsBySurvey = userId => {
  let account = selectActiveAccount(store.getState());
  let accountCopy = _.cloneDeep(account);
  let user = accountCopy.users.find(user => user._id === userId);
  let shareGroups = {
    entities: [],
    questions: [],
    surveys: [],
    steps: [],
  };
  // let shareGroupCopy = _.cloneDeep(shareGroups);
  for (let shareType in user?.sharedItems) {
    switch (shareType) {
      case 'entities':
        shareGroups.entities = user?.sharedItems[shareType]
          .filter(entity => entity.shareTarget)
          .map(entity => {
            let detailedEntity = getDetailedEntityById(entity.entityId);
            let surveyCount = totalSurveyCount(detailedEntity);
            return {
              ...entity,
              shareName: detailedEntity?.name,
              valid: surveyCount?.totalSurveysCount === surveyCount?.completedSurveysCount,
              filling: {
                total: surveyCount?.totalSurveysCount,
                filled: surveyCount?.completedSurveysCount,
              },
            };
          });
        break;
      case 'questions':
        shareGroups.questions = user?.sharedItems[shareType]
          .filter(question => question.shareTarget)
          .map(async question => {
            let detailedEntity = getDetailedEntityById(question.entityId);
            let detailedQuestion = await getDetailedQuestionById(question.questionId, question.surveyId);
            let detailedStep = getDetailedStepById(question.surveyStepId, question.surveyId);
            let responses = getResponsesBySurveyId(question.surveyId, detailedEntity);
            let versionResponses = responses?.versions.find(version => version?._id === question?.surveyVersionId).versionResponses;
            let linkedQuestions = getLinkedQuestions(
              question.surveyStepId,
              detailedQuestion,
              question.surveyId,
              question.surveyVersionId,
              question.entityId
            );
            let questionAndLinked = [];

            questionAndLinked.push({
              ...question,
              versionName: getSurveyVersionById(detailedEntity, question.surveyId, question.surveyVersionId)?.versionName,
              shareName: detailedQuestion?.label,
              value: versionResponses?.[detailedQuestion.name],
              valid:
                versionResponses?.[detailedQuestion.name] !== undefined &&
                versionResponses?.[detailedQuestion.name] !== null &&
                versionResponses?.[detailedQuestion.name] !== '' &&
                versionResponses?.[detailedQuestion.name]?.length > 0,
              entityName: detailedEntity?.name,
              stepName: detailedStep?.name,
            });

            linkedQuestions?.forEach(linkedQuestion => {
              questionAndLinked.push({
                linked: true,
                versionName: getSurveyVersionById(detailedEntity, question.surveyId, question.surveyVersionId)?.versionName,
                shareName: linkedQuestion?.label,
                entityName: detailedEntity?.name,
                stepName: detailedStep?.name,
                valid: !!versionResponses?.[linkedQuestion.name],
                value: versionResponses?.[linkedQuestion.name],
              });
            });

            return questionAndLinked;
          })
          .flat();
        break;
      case 'surveyVersions':
        shareGroups.surveys = user?.sharedItems[shareType]
          .filter(survey => survey.shareTarget)
          .map(async survey => {
            let detailedEntity = getDetailedEntityById(survey.entityId);
            let detailedSurvey = await getDetailedSurveyById(survey.surveyId);
            let detailedVersion = getSurveyVersionById(detailedEntity, survey.surveyId, survey.surveyVersionId);
            let fullSurveyVersion = await getSurveyVersionFullList(detailedEntity, detailedSurvey, detailedVersion);

            return {
              ...survey,
              shareName: detailedVersion?.versionName,
              entityName: detailedEntity?.name,
              valid: fullSurveyVersion.filter(step => step.fillingPercentage === 100).length === fullSurveyVersion.length,
              filling: {
                filled: fullSurveyVersion.filter(step => step.fillingPercentage === 100).length,
                total: fullSurveyVersion.length,
              },
            };
          });
        break;
      case 'surveySteps':
        shareGroups.steps = user?.sharedItems[shareType]
          .filter(step => step.shareTarget)
          .map(async step => {
            let detailedEntity = await getDetailedEntityById(step.entityId);
            let detailedSurvey = await getDetailedSurveyById(step.surveyId);
            let detailedVersion = getSurveyVersionById(detailedEntity, step.surveyId, step.surveyVersionId);
            let detailedStep = getDetailedStepById(step.surveyStepId, step.surveyId);
            let stepRequiredQuestions = await getSurveyStepRequiredQuestions(detailedEntity, detailedSurvey, detailedVersion, detailedStep.name);
            let filledQuestions = await getSurveyStepFilledRequiredQuestions(detailedEntity, detailedSurvey, detailedVersion, detailedStep.name);
            return {
              ...step,
              versionName: detailedVersion?.versionName,
              shareName: detailedStep?.name,
              entityName: getDetailedEntityById(step.entityId)?.name,
              surveyName: detailedSurvey?.name,
              valid: stepRequiredQuestions?.amount === filledQuestions?.amount,
              filling: {
                total: stepRequiredQuestions?.amount,
                filled: filledQuestions?.amount,
              },
            };
          });
        break;
    }
  }
  let sharedDetails = getSharedDetails(shareGroups);
  let isFulfilled = sharedDetails.filter(item => !item.valid).length === 0;
  return { isFulfilled, sharedDetails, shareGroups };
};

const getSharedDetails = shareGroups => {
  let sharedDetails = [];
  for (let shareType in shareGroups) {
    if (shareGroups[shareType].length === 0) continue;
    sharedDetails.push(
      shareGroups[shareType]?.map((shareItem, index) => {
        return {
          questionValue: shareItem.value,
          entityName: shareItem.entityName,
          surveyName: shareItem.surveyName,
          stepName: shareItem.stepName,
          versionName: shareItem.versionName,
          value: shareItem.shareName,
          filling: shareItem.filling,
          valid: shareItem.valid,
          linked: shareItem.linked,
          key: index,
          type: shareType,
        };
      })
    );
  }
  return sharedDetails.flat();
};

// **********************************************************************
// ******************   FIGURES AND STATISTICS  *********************
// **********************************************************************

// Account
export const getAccountVersionAmount = account => {
  let versionAmount = 0;
  account?.entities?.map(entity => {
    entity?.responses?.map(response => {
      versionAmount += response?.versions?.filter(versionResponse => versionResponse.versionResponses).length;
    });
  });
  return versionAmount;
};

export const getAccountProductAmount = account => {
  const state = store.getState();
  let contentfulSurveys = selectSurveys(state);
  let productAmount = 0;
  let versionableSurveys = contentfulSurveys?.filter(survey => survey.versioning.enabled);

  account?.entities?.map(entity => {
    entity?.responses
      ?.filter(response => versionableSurveys?.some(versionableSurvey => versionableSurvey.id === response.surveyId))
      .map(response => {
        productAmount += response?.versions?.length;
      });
  });
  return productAmount;
};

export const getAccountConformityPercentage = account => {
  let accountConformityPercentage = 0;
  const totalEntities = account?.entities?.length;

  account?.entities.forEach(entity => {
    accountConformityPercentage = accountConformityPercentage + Math.round(getEntityConformityPercentage(entity) / totalEntities);
  });
  return Number.isNaN(accountConformityPercentage) ? 0 : accountConformityPercentage;
};

export const getCompletedEntityAmount = account => {
  let completedEntityAmount = 0;
  account?.entities?.map(entity => {
    if (getEntityConformityPercentage(entity) >= 90) {
      completedEntityAmount++;
    }
  });
  return completedEntityAmount;
};

export const getAccountCounters = account => {
  let assistanceRequests = 0;
  let userRights = 0;
  let dataViolation = 0;
  account.entities.forEach(entity => {
    entity.counters.forEach(counter => {
      switch (counter.id) {
        case 'assistance_requests':
          assistanceRequests = assistanceRequests + counter.amount;
          return;
        case 'user_rights':
          userRights = userRights + counter.amount;
          return;
        case 'data_violation':
          dataViolation = dataViolation + counter.amount;
          return;
        default:
          return;
      }
    });
  });
  return { assistanceRequests, userRights, dataViolation };
};

// Entities
export const getEntityConformityPercentage = entity => {
  let conformityPercentage = 0;
  let isEntityValidated = entity.submission?.validated;
  conformityPercentage = isEntityValidated ? conformityPercentage + 10 : conformityPercentage;
  let isEntityWithDPO = entity.conformitySteps?.find(conformityStep => conformityStep.name === 'dpo')?.isValidated;
  conformityPercentage = isEntityWithDPO ? conformityPercentage + 10 : conformityPercentage;
  if (entity.actionPlan?.actions?.length > 0) {
    let actionPlanCompletion =
      (entity.actionPlan?.actions?.filter(action => !['Demande', 'Audit'].includes(action.category) && action.status === 'completed')?.length /
        entity.actionPlan?.actions?.length) *
      100;
    conformityPercentage = actionPlanCompletion >= 90 ? conformityPercentage + 80 : conformityPercentage;
  }
  return Math.round(conformityPercentage);
};

export const getEntityTotalFillingPercentage = async entity => {
  let totalPercentage = 0;
  let surveyList = (await getFullSurveyList(entity)).filter(survey => !isNaN(survey.fillingPercentage));
  surveyList.forEach(survey => {
    totalPercentage += survey?.fillingPercentage || 0;
  });

  let totalFillingPercentage = Math.round(totalPercentage / surveyList.length);
  return isNaN(totalFillingPercentage) ? 0 : totalFillingPercentage;
};

export const getEntityVersionsBySurveyId = (entity, surveyId, selectedAccount?: any) => {
  const entities = selectedAccount ? selectedAccount.entities : selectActiveAccount(store.getState()).entities;
  const targetEntity = entities.find(entityItem => entityItem._id === entity._id);
  return targetEntity?.responses?.find(entityResponse => entityResponse.surveyId === surveyId)?.versions;
};

export const getDetailedEntityById = (entityId: string) => {
  const activeAccount = selectActiveAccount(store.getState());
  return activeAccount.entities.find((entity: any) => entity._id === entityId);
};

export const getSelectedAccountEntityById = (entityId: string) => {
  const selectedAccount = selectSelectedAccount(store.getState());
  return selectedAccount.entities.find((entity: any) => entity._id === entityId);
};

export const getEntityCompletedVersionAmount = entity => {
  let completedVersionAmount = 0;
  entity?.responses?.map(survey => {
    survey?.versions.map(async version => {
      if (version?.completion?.completionPercentage === 100) {
        completedVersionAmount++;
      }
    });
  });
  return completedVersionAmount;
};

export const filterSharedEntities = entities => {
  let user = selectUser(store.getState());
  return user.role === 'guest'
    ? entities.filter(entityItem =>
        user?.sharedItems?.entities?.some(sharedEntity => sharedEntity.entityId === 'all' || sharedEntity.entityId === entityItem._id)
      )
    : entities;
};

export const isEntityConformityOK = async entity => {
  let surveyList = await getFullSurveyList(entity);
  let conformityOK = true;

  surveyList.forEach(survey => {
    if (survey.fillingPercentage !== 100 && !Number.isNaN(survey.fillingPercentage)) {
      conformityOK = false;
    }
  });
  return conformityOK;
};

export const totalSurveyCount = entity => {
  let totalSurveysCount = 0;
  let completedSurveysCount = 0;
  let authorizedSurveysIds = getAuthorizedSurveys()?.map(survey => survey.id);

  entity?.responses?.map(response => {
    if (authorizedSurveysIds?.includes(response.surveyId)) {
      response?.versions.map(async version => {
        totalSurveysCount++;
        const percentage = version?.completion?.completionPercentage;
        if (percentage === 100) {
          completedSurveysCount++;
        }
      });
    }
  });

  const percentage = Math.round((completedSurveysCount / totalSurveysCount) * 100);

  return { completedSurveysCount, totalSurveysCount, percentage };
};

export const isAnySubmittedEntity = account => account?.entities?.some(entity => !!entity?.submission?.submitted);

// Survey
export const getFullSurveyList = async entity => {
  let authorizedSurveys = getAuthorizedSurveys();
  let surveyList = authorizedSurveys.map(async survey => {
    let detailedSurvey = await getDetailedSurveyById(survey.id);
    return {
      ...survey,
      requiredQuestions: getSurveyRequiredQuestions(detailedSurvey, entity),
      filledRequiredQuestions: getSurveyVersionFilledRequiredQuestions(entity, detailedSurvey).amount,
      fillingPercentage: Math.round(
        (getSurveyVersionFilledRequiredQuestions(entity, detailedSurvey).amount / getSurveyRequiredQuestions(detailedSurvey, entity).amount) * 100
      ),
    };
  });
  await Promise.all(surveyList);
  return surveyList;
};

export const getAuthorizedSurveysByQuestions = async authorizedSurveys => {
  let questionsOverview = {};
  let promises = authorizedSurveys.map(async survey => {
    let fullSurvey = await getDetailedSurveyById(survey.id);
    fullSurvey.steps.forEach(step => {
      step.form.forEach(question => {
        if (!questionsOverview.hasOwnProperty(question.name)) {
          questionsOverview[question.name] = [{ name: fullSurvey.name, id: fullSurvey.id }];
        } else {
          questionsOverview[question.name].push({ name: fullSurvey.name, id: fullSurvey.id });
        }
      });
    });
    return survey;
  });
  await Promise.all(promises);
  return questionsOverview;
};

export const getSurveysCompletionPercentageByEntity = entity => {
  let sumCompletionPercentage = 0;
  let versionCount = 0;

  entity.responses.forEach(response => {
    if (response.versions) {
      response.versions.forEach(version => {
        sumCompletionPercentage += version.completion?.completionPercentage || 0;
        versionCount += 1;
      });
    }
  });
  return versionCount === 0 ? 0 : Math.round(sumCompletionPercentage / versionCount);
};

export const getSurveysCompletionPercentageByAccount = account => {
  if (!account) return 0;

  let totalCompletionPercentage = 0;
  const sharedEntities = filterSharedEntities(account.entities);

  if (!sharedEntities || sharedEntities.length === 0) return 0;

  const totalEntities = sharedEntities.length;

  const completionPercentages = sharedEntities.map(entity => {
    return getSurveysCompletionPercentageByEntity(entity);
  });

  totalCompletionPercentage = completionPercentages.reduce((accumulator, currentPercentage) => accumulator + currentPercentage, 0) / totalEntities;

  return Number.isNaN(totalCompletionPercentage) ? 0 : Math.round(totalCompletionPercentage);
};

export const getSurveyRequiredQuestions = (survey, entity) => {
  const state = store.getState();
  let contentfulData = selectAllContentfulData(state);
  let requiredQuestions = [];
  contentfulData.surveys
    ?.find(ctfSurvey => ctfSurvey.id === survey?.id)
    ?.steps?.map(step => {
      return step.form.map(question => {
        if (question.required) {
          getEntityVersionsBySurveyId(entity, survey?.id)?.map(surveyVersion => {
            if (entity && handleConditions(question, surveyVersion)) {
              requiredQuestions.push({ name: question.name, version: surveyVersion.versionName });
            }
          });
        }
      });
    });
  return {
    questions: requiredQuestions,
    amount: requiredQuestions.length,
  };
};

export const getSurveyVersionFilledRequiredQuestions = (entity, survey) => {
  let requiredQuestions = getSurveyRequiredQuestions(survey, entity).questions;
  let currentResponsesSet = entity.responses.find(entityResponse => entityResponse.surveyId === survey?.id)?.versions;
  let currentResponses = currentResponsesSet && currentResponsesSet[0]?.versionResponses;
  let filledRequiredQuestions = [];
  if (!currentResponses) {
    return {
      questions: [],
      amount: 0,
    };
  }
  // Manage Multiple Versions
  if (survey.versioning.enabled && currentResponsesSet.length > 1) {
    requiredQuestions.map(question => {
      if (
        currentResponsesSet.find(version => version.versionName === question.version)?.versionResponses &&
        currentResponsesSet.find(version => version.versionName === question.version)?.versionResponses?.[question.name] !== undefined &&
        currentResponsesSet.find(version => version.versionName === question.version)?.versionResponses?.[question.name] !== null &&
        !_.isEmpty(currentResponsesSet.find(version => version.versionName === question.version)?.versionResponses?.[question.name])
      ) {
        filledRequiredQuestions.push({
          name: question.name,
          version: currentResponsesSet.find(version => version.versionName === question.version).versionName,
        });
      }
    });
  } else {
    requiredQuestions.map(question => {
      if (currentResponses[question.name] !== undefined && currentResponses[question.name] !== null && !_.isEmpty(currentResponses[question.name])) {
        filledRequiredQuestions.push({
          name: question.name,
          version: currentResponsesSet[0].versionName || 'version_1',
        });
      }
    });
  }
  return {
    questions: filledRequiredQuestions,
    amount: filledRequiredQuestions.length,
  };
};

export const filterSharedSurveys = surveys => {
  let user = selectUser(store.getState());
  return user.role === 'guest'
    ? surveys?.filter(survey => user.sharedItems.surveys.some(surveyItem => surveyItem.surveyId === 'all' || surveyItem.surveyId === survey.id))
    : surveys;
};

export const getDetailedSurveyById = async surveyId => {
  let contentfulData = selectAllContentfulData(store.getState());
  let targetSurvey = contentfulData.surveys?.find(survey => survey.id === surveyId);
  if (!targetSurvey?.steps) {
    let fullSurvey = await getSurveyById(surveyId);
    store.dispatch(enhanceSurvey(fullSurvey));
    return fullSurvey;
  }
  return targetSurvey;
};

export const isSurveyDisabled = (surveyId, isSelectedAccount: boolean) => {
  let activeAccount = selectActiveAccount(store.getState());
  let selectedAccount = selectSelectedAccount(store.getState());
  let account = isSelectedAccount ? selectedAccount : activeAccount;
  return !!account.entities[0]?.responses.find(survey => survey.surveyId === surveyId)?.disabled;
};

// Version
export const getSurveyVersionAmount = entity => {
  let versionAmount = 0;
  entity?.responses?.map(response => {
    versionAmount += response?.versions?.filter(versionResponse => versionResponse.versionResponses).length;
  });
  return versionAmount;
};

export const getSurveyVersionFullList = async (entity, survey, version) => {
  let authorizedSurveys = getAuthorizedSurveys();
  let fullSurvey = authorizedSurveys.find(surveyItem => {
    if (surveyItem.id === survey.id) {
      return !!surveyItem.steps;
    }
    return false;
  });
  if (!fullSurvey) {
    fullSurvey = await getDetailedSurveyById(survey.id);
  }
  let surveyVersionList = [];

  surveyVersionList = fullSurvey?.steps?.map(async step => {
    let filledRequiredQuestions = (await getSurveyStepFilledRequiredQuestions(entity, fullSurvey, version, step.name)).amount;
    let requiredQuestions = await getSurveyStepRequiredQuestions(entity, fullSurvey, version, step.name);
    let ratio = filledRequiredQuestions / requiredQuestions.amount;
    let validPercentage = Number.isNaN(ratio) ? 0 : ratio;
    return {
      ...step,
      requiredQuestions,
      filledRequiredQuestions,
      fillingPercentage: Math.round(validPercentage * 100),
    };
  });

  surveyVersionList = await Promise.all(surveyVersionList);

  return surveyVersionList;
};

export const getSurveyVersionCompletionPercentage = async (entity, surveyId, version) => {
  let survey = await getDetailedSurveyById(surveyId);
  let requiredQuestions = getSurveyRequiredQuestions(survey, entity).questions.filter(question => question.version === version.versionName).length;
  let filledRequiredQuestions = getSurveyVersionFilledRequiredQuestions(entity, survey).questions.filter(
    question => question.version === version.versionName
  ).length;
  let percentage = Math.round((filledRequiredQuestions / requiredQuestions) * 100);
  return isNaN(percentage) ? 0 : percentage;
};

export const filterSharedVersions = versions => {
  let user = selectUser(store.getState());
  return user.role === 'guest'
    ? versions.filter(version =>
        user.sharedItems.surveyVersions.some(versionItem => versionItem.surveyVersionId === 'all' || versionItem.surveyVersionId === version._id)
      )
    : versions;
};

export const getSurveyVersionById = (entity, surveyId, versionId) => {
  return entity?.responses
    ?.find(entityResponse => entityResponse.surveyId === surveyId)
    ?.versions.find(version => {
      return version._id === versionId;
    });
};

// Step
export const getSurveyStepRequiredQuestions = async (entity, survey, version, stepName) => {
  if (!version) return { questions: [], amount: 0 };
  let requiredQuestions = [];
  const detailedSurvey = await getDetailedSurveyById(survey.id);
  const state = store.getState();
  const contentfulData = selectAllContentfulData(state);
  let nonVersionableSurveyIds = contentfulData.surveys
    .filter(survey => !survey.versioning.enabled)
    .map(survey => {
      return survey.id;
    });

  const nonVersionableSurveysWithoutCurrentSurvey = entity?.responses
    .filter(survey => nonVersionableSurveyIds.includes(survey.surveyId))
    .filter(survey => survey.surveyId !== survey.id);
  let dataset = {};
  nonVersionableSurveysWithoutCurrentSurvey?.forEach(survey => {
    if (survey.versions[0]?.versionResponses) {
      dataset = { ...dataset, ...survey.versions[0]?.versionResponses };
    }
  });

  detailedSurvey?.steps
    ?.filter(step => step.name === stepName)
    .forEach(step => {
      step.form.forEach(question => {
        if (question.required) {
          entity?.responses
            .find(entityResponse => {
              return entityResponse.surveyId === survey.id;
            })
            ?.versions.filter(versionItem => {
              return versionItem.versionName === version.versionName;
            })
            ?.forEach(surveyVersion => {
              if (entity && handleConditions(question, { ...surveyVersion.versionResponses, ...dataset })) {
                requiredQuestions.push({ name: question.name, version: surveyVersion.versionName });
              }
            });
        }
      });
    });
  return {
    questions: requiredQuestions,
    amount: requiredQuestions.length,
  };
};

export const filterSharedSteps = steps => {
  let user = selectUser(store.getState());
  return user.role === 'guest'
    ? steps.filter(step => user.sharedItems.surveySteps.some(stepItem => stepItem.surveyStepId === 'all' || stepItem.surveyStepId === step.id))
    : steps;
};

export const getSurveyStepFilledRequiredQuestions = async (entity, survey, version, stepName) => {
  if (!version) return { questions: [], amount: 0 };
  let requiredQuestions = (await getSurveyStepRequiredQuestions(entity, survey, version, stepName)).questions;
  let currentResponses = entity.responses
    .find(entityResponse => entityResponse.surveyId === survey.id)
    ?.versions.find(versionItem => versionItem.versionName === version.versionName)?.versionResponses;
  let filledRequiredQuestions = [];
  if (!currentResponses) {
    return {
      questions: [],
      amount: 0,
    };
  }

  requiredQuestions.map(question => {
    if (currentResponses[question.name] !== undefined && currentResponses[question.name] !== null && !_.isEmpty(currentResponses[question.name])) {
      filledRequiredQuestions.push({
        name: question.name,
        version: version.versionName,
      });
    }
  });

  return {
    questions: filledRequiredQuestions,
    amount: filledRequiredQuestions.length,
  };
};

export const getFilledRequiredQuestions = async (entity, survey, version, dataset) => {
  let fullSurveyVersion = await getSurveyVersionFullList(entity, survey, version);
  let filledRequiredQuestions = 0;
  fullSurveyVersion.filter(step => handleConditions(step, dataset)).map(step => (filledRequiredQuestions += step.filledRequiredQuestions));
  return filledRequiredQuestions;
};

export const getRequiredQuestions = async (entity, survey, version, dataset) => {
  let fullSurveyVersion = await getSurveyVersionFullList(entity, survey, version);
  let requiredQuestions = 0;
  fullSurveyVersion.filter(step => handleConditions(step, dataset)).forEach(step => (requiredQuestions += step.requiredQuestions.amount));
  return requiredQuestions;
};

export const getDetailedStepById = (stepId, surveyId) => {
  let contentfulData = selectAllContentfulData(store.getState());
  return contentfulData.surveys?.find(survey => survey.id === surveyId).steps.find(step => step.id === stepId);
};

// Question

export const isSharedQuestionById = question => {
  let user = selectUser(store.getState());
  return user.role === 'guest'
    ? user.sharedItems.questions?.some(questionItem => questionItem.questionId === 'all' || questionItem.questionId === question.id)
    : true;
};

export const getDetailedQuestionById = async (questionId, surveyId) => {
  let survey = await getDetailedSurveyById(surveyId);
  let detailedQuestion = null;
  survey.steps.forEach(step => {
    step.form.forEach(question => {
      if (question.id === questionId) {
        detailedQuestion = question;
      }
    });
  });
  return detailedQuestion;
};

export const isLinkedToShareQuestion = (question, surveyId, tempAccountVersionResponses?) => {
  let user = selectUser(store.getState());
  if (isSharedQuestionById(question)) return true;
  return user.sharedItems.questions?.some(sharedQuestion =>
    getLinkedQuestions(
      sharedQuestion.surveyStepId,
      sharedQuestion.questionId,
      sharedQuestion.surveyId,
      sharedQuestion.surveyVersionId,
      sharedQuestion.entityId,
      tempAccountVersionResponses
    )?.some(linkedQuestion => linkedQuestion.name === question.name)
  );
};

export const getLinkedQuestions = (stepId, question, surveyId, versionId, entityId, tempAccountVersionResponses?) => {
  let contentfulData = selectAllContentfulData(store.getState());
  let detailedEntity = getDetailedEntityById(entityId);
  let version = getSurveyVersionById(detailedEntity, surveyId, versionId);
  let formQuestions = contentfulData.surveys?.find(survey => survey.id === surveyId)?.steps.find(step => step.id === stepId).form;
  return formQuestions.filter(questionItem => {
    return isLinkedQuestion(questionItem, tempAccountVersionResponses ?? version?.versionResponses, formQuestions);
  });
};

export const isLinkedQuestion = (question, dataset, formQuestions) => {
  if (isSharedQuestionById(question)) return true;
  if (!question.conditions) {
    return false;
  } else {
    if (handleConditions(question, dataset)) {
      for (let condition of question.conditions) {
        let conditionedQuestion = formQuestions.find(question => question.name === condition.key);
        return isLinkedQuestion(conditionedQuestion, dataset, formQuestions);
      }
    } else {
      return false;
    }
  }
};

export const getQuestionInfosByName = (questionName, returnLabel = false, surveyId?) => {
  let authorizedSurveys = getAuthorizedSurveys();
  if (surveyId) {
    authorizedSurveys = authorizedSurveys.filter(survey => survey.id === surveyId);
  }
  let questionInfos = null;
  authorizedSurveys?.forEach(survey => {
    survey.steps.forEach(step => {
      step.form.forEach(question => {
        if (question.name === questionName) {
          if (!!questionInfos) {
            store.dispatch(setErrors({ title: 'Attention', message: `La question ${questionName} est liée à plusieurs questionnaires.` }));
            questionInfos = { error: 'This question name has more than one linked survey' };
            return;
          }
          questionInfos = {
            surveyId: survey.id,
            stepId: step.id,
            questionId: question.id,
            ...(returnLabel && { label: question.label, options: question.options }),
          };
        }
      });
    });
  });

  return questionInfos;
};

export const getAuditQuestionInfosByName = (surveyData, questionName) => {
  const SURVEY_ID = '2LGGPkFMcjg5G9SztSFP5y';

  const foundQuestion = surveyData?.steps?.flatMap(step => step.form ?? []).find(question => question.name === questionName);

  const { id, label, options } = foundQuestion;

  const stepId = surveyData?.steps.find(step => step.form?.some(question => question.id === id))?.id;

  return {
    surveyId: SURVEY_ID,
    stepId,
    questionId: id,
    label,
    options,
  };
};

//Responses
export const getResponsesBySurveyId = (surveyId, entity) => {
  return entity.responses.find(response => response.surveyId === surveyId);
};

//S3

export const handleUploadFilesToS3 = async (files: Array<any>, entityId, accountId, path) => {
  if (!files.length) return;
  const formData = new FormData();
  let filePath: {} = {};
  formData.append('metadata', '');
  files.forEach((file, idx) => {
    let newFile = new File([file], encodeURI(file.name) + idx, { type: file.type });
    let cleanPath = (file.path.slice(0, 1) === '/' ? file.path.slice(1) : file.path).replaceAll(' ', '_');
    let cleanCurrentPath = path.slice(path.length - 1) === '/' ? path.slice(0, -1) : path;
    filePath[encodeURI(file.name) + idx] = `${cleanCurrentPath !== '' ? '/' : ''}${cleanCurrentPath}/${cleanPath}`;
    formData.append('documents', newFile);
  });

  formData.set(
    'metadata',
    JSON.stringify({
      path: filePath,
      accountId,
      entityId,
    })
  );

  await uploadDocumentToS3(formData);
};

export const getFileNameFromKey = key => {
  if (!key) return;
  let parts = key.split('/');
  return parts[parts.length - 1].replaceAll('_', ' ');
};

export const getFilePathFromKey = (key, last = false) => {
  if (!key) return;
  let parts = key.split('/');
  let result = parts.slice(3, parts.length - 1).join('/');
  if (last) {
    result = result.split('/')[0];
  }
  return result;
};

export const getFileType = fileName => fileName?.split('.').pop();

// Actions
export const getAuthorizedProducts = () => {
  const state = store.getState();
  let contentfulData = selectAllContentfulData(state);
  let activeAccount = selectSelectedAccount(state) || selectActiveAccount(state);
  return contentfulData.surveys?.filter(survey => {
    return activeAccount?.actionRights.surveys?.some(surveyRights => surveyRights.id === survey.id);
  });
};

export const getActionProductName = productId => {
  const authorizedProducts = getAuthorizedProducts()?.filter(product => product.versioning.enabled);
  return authorizedProducts?.find(product => product.id === productId)?.name;
};

export const getActionDetail = (entityId, actionId, productId, versionId?) => {
  const state = store.getState();
  const contentfulData = selectAllContentfulData(state)?.userActions?.find(action => action.id === actionId);
  const entity = selectActiveAccount(state)?.entities?.find(entity => {
    return entity._id === entityId;
  });
  const action = entity?.actionPlan?.actions?.find(action => {
    const { isEntityScoop, versionProductId, relatedProductId, versionProductName, id } = action;
    if (isEntityScoop) {
      return id === actionId && versionProductId === versionId;
    } else {
      if (versionProductName && !versionProductId) {
        const version = getVersionIdByName(entityId, relatedProductId, versionProductName);
        return id === actionId && action.versionProductId === version && action.relatedProductId === relatedProductId;
      } else if (versionProductId) {
        return id === actionId && action.versionProductId === versionProductId && action.relatedProductId === relatedProductId;
      } else {
        return action.id === actionId && action.relatedProductId === productId && action.versionProductId === versionId;
      }
    }
  });
  return { ...contentfulData, ...action };
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string, userId: string) => {
  if (name) {
    const initials = name.includes(' ')
      ? `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`
      : name
          ?.toUpperCase()
          ?.split(' ')
          .map(word => word[0])
          .join('');

    const color = getRandomColorFromName(initials, userId);
    return {
      sx: {
        bgcolor: color ? color : stringToColor(name),
      },
      children: `${initials}`,
    };
  }
};

export const normalizeString = str => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const compareQueryToActionName = (actionName, query) => {
  return normalizeString(actionName.toLowerCase()).includes(normalizeString(query.toLowerCase().trim()));
};

export const compareByUrgency = (a, b) => {
  const urgencyOrder = { low: 0, medium: 1, high: 2 };
  const urgencyA = urgencyOrder[a.urgency];
  const urgencyB = urgencyOrder[b.urgency];

  return urgencyB - urgencyA;
};

export const compareByStatus = (a, b) => {
  const statusOrder = { pending: 0, ongoing: 0, completed: 1 };
  const statusA = statusOrder[a.status];
  const statusB = statusOrder[b.status];

  return statusA - statusB;
};

export const getResponsibleName = (email: string): string => {
  const state = store.getState();
  const activeAccount = selectSelectedAccount(state) || selectActiveAccount(state);
  const userInfo = activeAccount.users.find(user => user.email === email);
  if (userInfo) {
    if (userInfo.name && userInfo.name !== '') {
      if (userInfo?.firstName && userInfo?.firstName !== '') {
        return `${userInfo?.firstName} ${userInfo.name}`;
      }
      return `${userInfo.name}`;
    }
    return email;
  }
  return email;
};

export const getUserNameById = (id: string): string | undefined => {
  const state = store.getState();
  const activeAccount = selectSelectedAccount(state) || selectActiveAccount(state);

  const userInfo = activeAccount.users.find(user => user._id === id);
  if (userInfo) {
    if (userInfo.name && userInfo.name !== '') {
      if (userInfo?.firstName && userInfo?.firstName !== '') {
        return `${userInfo.name} ${userInfo?.firstName}`;
      }
      return `${userInfo.name}`;
    }
    return userInfo.email;
  }
};

export const removeQuestionByQuestionName = (entityId, questionName, versionId?) => {
  const state = store.getState();
  let selectedAccount = selectSelectedAccount(state);
  let activeAccount = selectActiveAccount(state);
  let currentUser = selectUser(state);
  let entitiesCopy = _.cloneDeep((selectedAccount ?? activeAccount).entities);
  let targetEntityResponses = entitiesCopy.find(entity => entity._id === entityId)?.responses;
  let questionInfos = undefined;
  let versionResponses = { [questionName]: null };
  targetEntityResponses.forEach(survey => {
    survey.versions.forEach(version => {
      if (version._id === versionId) {
        questionInfos = getQuestionInfosByName(questionName, false, survey.surveyId);
      }
    });
  });

  if (questionInfos) {
    store.dispatch(
      updateEntityResponses({
        accountId: (selectedAccount ?? activeAccount)._id,
        userId: currentUser._id,
        entityId,
        versionId,
        surveyId: questionInfos.surveyId,
        stepId: questionInfos.stepId,
        responses: versionResponses,
        loader: false,
      })
    );
  }
};

export const getVersionName = (entityId, productId, versionId) => {
  const state = store.getState();
  let selectedAccount = selectSelectedAccount(state);
  let activeAccount = selectActiveAccount(state);
  return activeAccount
    ? activeAccount.entities
        .find(entity => entity._id === entityId)
        ?.responses?.find(response => response.surveyId === productId)
        ?.versions?.find(version => version._id === versionId)?.versionName
    : selectedAccount.entities
        .find(entity => entity._id === entityId)
        ?.responses?.find(response => response.surveyId === productId)
        ?.versions?.find(version => version._id === versionId)?.versionName;
};

export const getVersionIdByName = (entityId, productId, versionName, admin = false) => {
  const state = store.getState();
  let selectedAccount = selectSelectedAccount(state);
  let activeAccount = selectActiveAccount(state);
  return !admin
    ? activeAccount.entities
        .find(entity => entity._id === entityId)
        ?.responses?.find(response => response.surveyId === productId)
        ?.versions?.find(version => version.versionName === versionName)?._id
    : selectedAccount.entities
        .find(entity => entity._id === entityId)
        ?.responses?.find(response => response.surveyId === productId)
        ?.versions?.find(version => version.versionName === versionName)?._id;
};

export const verifyResponsesMatchConditions = (conditions, verResponses = null, nonVersResponses = null) => {
  let isEntityScoop = false;
  if (!conditions || conditions.length === 0) {
    return { isMatchConditions: true, isEntityScoop: true };
  }

  for (const condition of conditions) {
    const { key, value } = condition;
    if (!!verResponses) {
      if (verResponses[key] !== value) {
        if (Array.isArray(verResponses[key])) {
          if (!verResponses[key].includes(value)) {
            if (nonVersResponses[key] !== value) {
              if (Array.isArray(nonVersResponses[key])) {
                if (!nonVersResponses[key].includes(value)) {
                  return { isMatchConditions: false, isEntityScoop: false };
                } else {
                  isEntityScoop = true;
                }
              }
              return { isMatchConditions: false, isEntityScoop: false };
            } else {
              isEntityScoop = true;
            }
          }
        } else {
          if (nonVersResponses[key] !== value) {
            if (Array.isArray(nonVersResponses[key])) {
              if (!nonVersResponses[key].includes(value)) {
                return { isMatchConditions: false, isEntityScoop: false };
              } else {
                isEntityScoop = true;
              }
            } else return { isMatchConditions: false, isEntityScoop: false };
          } else {
            isEntityScoop = true;
          }
        }
      }
    } else {
      if (nonVersResponses[key] !== value) {
        if (Array.isArray(nonVersResponses[key])) {
          if (!nonVersResponses[key].includes(value)) {
            return { isMatchConditions: false, isEntityScoop: false };
          } else {
            isEntityScoop = true;
          }
        } else return { isMatchConditions: false, isEntityScoop: false };
      } else {
        isEntityScoop = true;
      }
    }
  }

  return { isMatchConditions: true, isEntityScoop };
};

export const getActionConditions = actionId => {
  const state = store.getState();
  const contentfulData = selectAllContentfulData(state);
  return contentfulData.userActions.find(action => action.id === actionId);
};

export const emphasizedText = text => {
  const emphasizedText = text.replace(/__(.*?)__/g, '<b>$1</b>');
  const textWithLineBreaks = emphasizedText.replace(/\n/g, '<br/>');
  return textWithLineBreaks;
};

export const retrieveSurveysResponses = (selectedAccount, selectedEntity) => {
  // Retrieve needed data => versionable & non versionable products
  const nonVersProducts = getAuthorizedProducts()
    ?.filter(product => !product.versioning.enabled)
    ?.map(product => {
      return { id: product.id, name: product.name };
    });

  const versProducts = getAuthorizedProducts()
    ?.filter(product => product.versioning.enabled)
    ?.map(product => {
      return { id: product.id, name: product.name };
    });

  // Retrieve targeted entity responses
  const selectedEntityResponses = selectedAccount.entities.find(entity => entity._id === selectedEntity?._id)?.responses;

  // Retrieve responses for non versionable products and format them
  const nonVersProductResponses = selectedEntityResponses.filter(response => {
    const existsInNonVersProducts = nonVersProducts.some(product => product.id === response.surveyId);
    return existsInNonVersProducts ? true : false;
  });

  const nonVersSurveysAnswers = nonVersProductResponses.reduce((responses, survey) => {
    survey?.versions?.forEach(elt => {
      if (elt.versionResponses) {
        Object.assign(responses, elt.versionResponses);
      }
    });
    return responses;
  }, {});

  // Retrieve responses for versionable products and format them
  const versProductResponses = selectedEntityResponses.filter(response => {
    const existsInVersProducts = versProducts.some(product => product.id === response.surveyId);
    return existsInVersProducts ? true : false;
  });

  const versSurveysAnswers = versProductResponses.reduce((responses, survey) => {
    if (survey.versions.length > 0) {
      survey.versions.forEach(version => {
        if (version?.versionResponses && Object.keys(version?.versionResponses).length > 0) {
          responses.push({
            responses: version.versionResponses,
            relatedProductId: survey.surveyId,
            productName: getActionProductName(survey.surveyId),
            versionProductId: version._id,
            versionProductName: version.versionName,
          });
        }
      });
    }
    return responses;
  }, []);

  // return the formatted responses
  return { nonVersSurveysAnswers, versSurveysAnswers };
};

//
// Agenda utils
//
export const areTimesFilled = dayData => {
  // If allDay is true, no need to check startTime and endTime.
  if (dayData.allDay || (dayData.startTime === '' && dayData.endTime === '')) {
    return true;
  }
  // Check if both startTime and endTime are filled.
  if (dayData.startTime !== '' && dayData.endTime !== '') {
    // Parse the times as Date objects.
    const today = new Date();
    const startTime = new Date(today.toDateString() + ' ' + dayData.startTime);
    const endTime = new Date(today.toDateString() + ' ' + dayData.endTime);
    // Check if startTime is before endTime, indicating a valid time range.
    if (startTime < endTime) {
      return true;
    }
  }
  // If any of the conditions are not met, return false.
  return false;
};

export const isUnavailabilityDaysComplete = unavailabilityDays => {
  // Iterate through unavailabilityDays and check each day.
  for (const day of unavailabilityDays) {
    if (!areTimesFilled(day)) {
      return false; // At least one day is incomplete.
    }
  }
  return true; // All days are complete.
};

export const isSubmissionDisabled = (currentUser, currentEntity) => {
  let isAuthorized =
    currentUser?.role !== 'guest' ??
    currentUser?.sharedItems?.entities?.includes(sharedEntity => sharedEntity.entityId === currentEntity._id && sharedEntity.shareTarget);
  return currentEntity?.submission?.submitted || currentEntity?.submission?.validated || !isAuthorized;
};

export const addEventTracking = (category, action, name?, value?) => {
  // @ts-ignore
  var _paq = (window._paq = window._paq || []);
  let options = ['trackEvent', category, action];
  if (name) options.push(name);
  if (value) options.push(value);
  _paq.push(options);
};

// Others

export const compareObjects = (obj1, obj2) => {
  // Check if the objects have the same number of keys
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return true;
  }
  // Get the entries of obj1
  const entries1 = Object.entries(obj1);
  // Check if every entry matches the corresponding entry in obj2
  return !entries1.every(([key, value]) => obj2[key] === value);
};

export const getRandomColorFromName = (initials: string, id: string): string => {
  if (!initials || !id) return '#F49D1D';

  let r, g, b;

  const charCodeSum = (str: string) => {
    return str.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
  };

  const initialsSum = charCodeSum(initials);
  const idSum = charCodeSum(id);

  r = Math.max(((initialsSum * 9973 + idSum * 991) % 256) - 30, 0);
  g = Math.max(((initialsSum * 9967 + idSum * 997) % 256) - 30, 0);
  b = Math.max(((initialsSum * 9959 + idSum * 983) % 256) - 30, 0);

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const toHex = (value: number): string => {
  const hex = value.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

export const downloadDocumentsAsZip = async (docUrls, zipTitle) => {
  const zip = new JSZip();

  // Array to store promises for each fetch operation
  const fetchPromises = [];

  // Iterate through each document URL
  for (let i = 0; i < docUrls.length; i++) {
    const signedLink = await getSignedUrlByKey(docUrls[i]);
    const promise = fetch(signedLink.link, {
      headers: {
        'Content-Type': 'application/octet-stream', // Set content type to match S3 ResponseContentType
      },
      mode: 'cors',
      credentials: 'include',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch document: ${response.statusText}`);
        }
        return response.blob();
      })
      .then(blob => {
        // Extract filename from URL or use a custom one
        const filename = docUrls[i].substring(docUrls[i].lastIndexOf('/') + 1);
        // Add the file to the zip
        zip.file(filename, blob, { binary: true });
      })
      .catch(error => {
        console.error('Error fetching document:', error);
      });

    fetchPromises.push(promise);
  }

  // Wait for all fetch operations to complete
  await Promise.all(fetchPromises);

  // Generate the zip file asynchronously
  const content = await zip.generateAsync({ type: 'blob' });

  // Save the zip file using file-saver
  saveAs(content, `${zipTitle}.zip`);
};

export const processAnswersForAudits = (action, answers, isVersSurvey = false, entityId = null) => {
  const conditions = action?.conditions
    ?.filter(elt => elt.value === 'other')
    ?.map(elt => {
      // This is a special case
      if (elt.key === 'app_online_payment_detail') {
        return 'app_online_payment_other';
      }
      return `${elt.key}_${elt.value}`;
    });

  if (!conditions || conditions.length === 0) return [];

  return conditions.flatMap(condition => {
    const responseArray = isVersSurvey ? answers.flatMap(answer => answer?.responses[condition] || []) : answers?.[condition] || [];

    if (!Array.isArray(responseArray)) return [];

    return responseArray.map(element => ({
      ...action,
      ...(isVersSurvey ? answers.find(a => a?.responses[condition]?.includes(element)) : {}),
      subContractor: { name: element },
      relatedEntryName: condition.replace('_other', ''),
      responses: undefined,
      ...(!isVersSurvey && { isEntityScoop: true, versionProductId: entityId }),
    }));
  });
};

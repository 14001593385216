/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionContainer,
  Content,
  DashboardGenerationContainer,
  DocumentsList,
  EntitiesContainer,
  EntityContainer,
  EntityTitle,
  FixInputContainer,
  InputContainer,
  Tab,
  TabContainer,
  TopContainer,
  VersionChoiceContainer,
  VersionItem,
  VersionItemWarning,
} from './DashboardGeneration_.style';
import _ from 'lodash';

import { InputComponent } from '../form/InputComponent';
import { Button } from '../Button';

import { getLinksBetweenSurveysAndDocuments, getLinksBetweenSurveysAndRegistries, selectAllContentfulData } from '../../reducers/contentful.reducer';
import { selectAccount, selectUser, setDownloadOk, selectSelectedAccount } from '../../reducers/account.reducer';

import { ACCOUNT_TYPES } from '../../utils/constants';
import { getOneDoc } from '../docGeneration/docx-utils';
import { getOneExcel } from '../docGeneration/excel-utils';

let GENERATION_MODES = {
  WORD: 'docx',
  EXCEL: 'excel',
  ALL: 'all',
};

export const DashboardGeneration = () => {
  //redux
  const dispatch = useDispatch<any>();
  const contentfulData = useSelector(selectAllContentfulData);
  const account = useSelector(selectAccount);
  const user = useSelector(selectUser);
  const selectedAccount = useSelector(selectSelectedAccount);

  //state
  const [generationMode, setGenerationMode] = useState(GENERATION_MODES.WORD);
  const [targetGeneration, setTargetGeneration] = useState(null);
  const [targetZipContent, setTargetZipContent] = useState([]);
  const [lang, setLang] = useState('FR');
  const [linkedSurveys, setLinkedSurveys] = useState([]);

  const getDocxOptions = () => {
    return (
      contentfulData.documents
        //.filter(document => selectedAccount?.actionRights?.documents?.some(documentRight => document.id === documentRight.id))
        .filter(document => document.lang === lang)
        .map(document => ({ value: document.id, label: document.name })) || []
    );
  };

  const handleTargetDoc = (name, value) => {
    let newGenerationEntity = {
      ...targetGeneration,
      docId: value,
    };
    if (value === undefined) {
      delete newGenerationEntity.docId;
    }
    setTargetGeneration(newGenerationEntity);
  };

  const handleTargetGeneration = (name, value) => {
    let newGenerationEntity = {
      ...targetGeneration,
      [name]: value,
    };
    setTargetGeneration(newGenerationEntity);
  };

  const handleTargetEntity = (targetName, value) => {
    let { name, id } = JSON.parse(value);
    let newGenerationEntity = {
      ...targetGeneration,
      name: name,
      entityId: id,
    };
    setTargetGeneration(newGenerationEntity);
  };

  const getEntityResponseByEntityName = entityName => {
    return selectedAccount?.entities?.find(entity => entity.name === entityName)?.responses;
  };

  const handleDocxSubmit = async () => {
    let values = getTargetedValues();
    await getOneDoc(values, targetGeneration.docId, targetGeneration.name, selectedAccount._id, lang);
  };

  const getTargetedValues = () => {
    let targetedValues = {};
    let entityResponses = selectedAccount.entities.find(entity => entity.name === targetGeneration.name).responses;
    if (!entityResponses.length) {
      return;
    }
    entityResponses.forEach(surveyResponses => {
      targetedValues = {
        ...targetedValues,
        ...(targetGeneration[surveyResponses.surveyId]
          ? surveyResponses.versions.find(version => version.versionName === targetGeneration[surveyResponses.surveyId])?.versionResponses
          : surveyResponses.versions[0]?.versionResponses),
      };
    });
    return targetedValues;
  };

  const handleExcelSubmit = async () => {
    let values = getTargetedValues();
    await getOneExcel(values, targetGeneration.registryId, targetGeneration.name, targetGeneration.entityId, selectedAccount._id);
  };

  const getRegistriesOptions = () => {
    return (
      contentfulData.registries
        .filter(registry => selectedAccount?.actionRights?.registries?.some(registryRights => registry.id === registryRights.id))
        .filter(document => document.lang === lang)
        .map(registry => ({ value: registry.id, label: registry.name })) || []
    );
  };

  const handleTargetRegistry = (name, value) => {
    let newGenerationEntity = {
      ...targetGeneration,
      registryId: value,
    };
    if (value === undefined) {
      delete newGenerationEntity.registryId;
    }
    setTargetGeneration(newGenerationEntity);
  };

  const handleTargetLang = (name, value) => setLang(value);

  const getLinkedSurveys = async (id, entityName) => {
    let surveys = [];
    let links = generationMode === GENERATION_MODES.WORD ? await getLinksBetweenSurveysAndDocuments() : await getLinksBetweenSurveysAndRegistries();
    if (!entityName || !links) {
      return;
    }
    if (!id) {
      surveys = _.cloneDeep(contentfulData.surveys).filter(survey =>
        selectedAccount.actionRights?.surveys?.some(userSurvey => userSurvey.id === survey.id)
      );
    } else {
      let linkedSurveys = generationMode === GENERATION_MODES.WORD ? links?.docsToSurveys : links?.registriesToSurveys;
      surveys = _.cloneDeep(contentfulData.surveys).filter(survey => linkedSurveys[id]?.some(linkedSurvey => linkedSurvey === survey.id));
    }

    let versions = surveys.map(survey => {
      let surveyResponsesVersions = getEntityResponseByEntityName(entityName)?.find(
        entityResponse => entityResponse.surveyId === survey.id
      )?.versions;
      let versionsNames = [];
      if (surveyResponsesVersions) {
        surveyResponsesVersions.forEach(surveyResponsesVersion => versionsNames.push(surveyResponsesVersion.versionName));
      }
      return {
        survey: survey,
        versioning: survey.versioning,
        versions: versionsNames,
      };
    });
    setLinkedSurveys(versions.filter(version => version.versioning.enabled).filter(version => version.versions.length));
  };

  const handleChangeGenerationMode = targetMode => {
    setGenerationMode(targetMode);
  };

  const getEntitiesOptions = () =>
    selectedAccount?.entities?.map(entity => ({
      label: entity.name.replaceAll('_', ' '),
      value: JSON.stringify({
        name: entity.name,
        id: entity._id,
      }),
    }));

  const getLangOptions = () => [
    { value: 'FR', label: 'Français' },
    { value: 'EN', label: 'Anglais' },
  ];

  const toggleZipContent = (document, entity, checked, docType) => {
    let targetZipCopy = _.cloneDeep(targetZipContent);
    let targetEntity = targetZipCopy.find(currentEntity => currentEntity.name === entity.name);

    document = { ...document, type: docType };

    if (!checked) {
      targetEntity.includedDocuments = targetEntity.includedDocuments.filter(currentDocument => currentDocument.value !== document.value);
      setTargetZipContent(targetZipCopy);
      return;
    }

    if (!targetEntity) {
      targetZipCopy.push({
        name: entity.name,
        includedDocuments: [document],
      });
    } else {
      targetEntity.includedDocuments.push(document);
    }

    setTargetZipContent(targetZipCopy);
    return;
  };

  const isCheckedZipContent = (document, entity) => {
    let targetEntity = targetZipContent.find(currentEntity => currentEntity.name === entity.name);
    if (!targetEntity) {
      return false;
    }
    return targetEntity.includedDocuments.some(currentDocument => currentDocument.label === document.label);
  };

  const isGenerationReady = () => {
    return (targetGeneration?.docId || targetGeneration?.registryId) && targetGeneration?.entityId && lang !== undefined;
  };

  const getInputValue = link => {
    return { label: targetGeneration[link.survey.id], value: targetGeneration[link.survey.id] };
  };

  useEffect(() => {
    dispatch(setDownloadOk(account?.activity?.remainingDownload > 0 ?? false));
  }, [user]);

  useEffect(() => {
    (async () => {
      if (!targetGeneration) return;
      await getLinkedSurveys(
        generationMode === GENERATION_MODES.WORD ? targetGeneration.docId : targetGeneration?.registryId,
        targetGeneration?.name
      );
    })();
  }, [targetGeneration]);

  return (
    <DashboardGenerationContainer>
      <TopContainer>
        <TabContainer>
          <Tab current={generationMode === GENERATION_MODES.WORD} onClick={() => handleChangeGenerationMode(GENERATION_MODES.WORD)}>
            WORD
          </Tab>
          {account?.accountType === ACCOUNT_TYPES.ADMIN && (
            <>
              <Tab current={generationMode === GENERATION_MODES.EXCEL} onClick={() => handleChangeGenerationMode(GENERATION_MODES.EXCEL)}>
                EXCEL
              </Tab>
            </>
          )}
        </TabContainer>
      </TopContainer>
      <Content>
        {generationMode === GENERATION_MODES.WORD && (
          <>
            <FixInputContainer>
              <InputContainer>
                <InputComponent
                  name={'word_choice'}
                  type={'select'}
                  label={'Choix du Document'}
                  options={getDocxOptions()}
                  onChange={handleTargetDoc}
                  clearable={true.toString()}
                  value={getDocxOptions().filter(value => {
                    return value.value === targetGeneration?.docId;
                  })}
                  sharable={false}
                />
              </InputContainer>
              <InputContainer>
                <InputComponent
                  key={'entity_choice'}
                  name={'entity_choice'}
                  type={'select'}
                  label={"Choix de l'entreprise"}
                  options={getEntitiesOptions()}
                  onChange={handleTargetEntity}
                  sharable={false}
                />
              </InputContainer>
              <InputContainer>
                <InputComponent
                  key={'lang_choice'}
                  name={'lang_choice'}
                  type={'select'}
                  label={'Choix de la langue'}
                  options={getLangOptions()}
                  onChange={handleTargetLang}
                  sharable={false}
                  value={{ value: 'FR', label: 'Français' }}
                />
              </InputContainer>
            </FixInputContainer>
            <VersionChoiceContainer>
              {targetGeneration?.name &&
                linkedSurveys?.map((link, index) => {
                  return (
                    <>
                      <VersionItem key={index}>
                        <InputComponent
                          key={link.survey.name + index}
                          name={link.survey.id}
                          type={'select'}
                          label={link.survey.name}
                          options={link.versions.map(version => ({
                            label: version.replaceAll('_', ' '),
                            value: version,
                          }))}
                          onChange={handleTargetGeneration}
                          value={getInputValue(link)}
                          sharable={false}
                        />
                        {link.versions.length > 1 && <VersionItemWarning>Veuillez sélectionner un service</VersionItemWarning>}
                      </VersionItem>
                    </>
                  );
                })}
            </VersionChoiceContainer>
          </>
        )}
        {generationMode === GENERATION_MODES.EXCEL && (
          <>
            <FixInputContainer>
              <InputContainer>
                <InputComponent
                  name={'excel_choice'}
                  type={'select'}
                  label={'Choix du Registre'}
                  options={getRegistriesOptions()}
                  onChange={handleTargetRegistry}
                  clearable={true.toString()}
                  value={getRegistriesOptions().filter(value => value.value === targetGeneration?.registryId)}
                  sharable={false}
                />
              </InputContainer>
              <InputContainer>
                <InputComponent
                  key={'entity_choice'}
                  name={'entity_choice'}
                  type={'select'}
                  label={"Choix de l'entreprise"}
                  options={getEntitiesOptions()}
                  onChange={handleTargetEntity}
                  sharable={false}
                />
              </InputContainer>
              <InputContainer>
                <InputComponent
                  key={'lang_choice'}
                  name={'lang_choice'}
                  type={'select'}
                  label={'Choix de la langue'}
                  options={getLangOptions()}
                  onChange={handleTargetLang}
                  sharable={false}
                  value={{ value: 'FR', label: 'Français' }}
                />
              </InputContainer>
            </FixInputContainer>
            <VersionChoiceContainer>
              {targetGeneration?.name &&
                linkedSurveys?.map((link, index) => {
                  return (
                    <>
                      <VersionItem key={index}>
                        <InputComponent
                          key={link.survey.name + index}
                          name={link.survey.id}
                          type={'select'}
                          label={link.survey.name}
                          options={link.versions.map(version => ({
                            label: version.replaceAll('_', ' '),
                            value: version,
                          }))}
                          onChange={handleTargetGeneration}
                          // value={targetGeneration[link.survey.id] || ''}
                          value={getInputValue(link)}
                          sharable={false}
                        />
                        {link.versions.length > 1 && <VersionItemWarning>Veuillez sélectionner un service</VersionItemWarning>}
                      </VersionItem>
                    </>
                  );
                })}
            </VersionChoiceContainer>
          </>
        )}
        {generationMode === GENERATION_MODES.ALL && (
          <EntitiesContainer>
            {selectedAccount.entities.map((entity, index) => (
              <EntityContainer key={index}>
                <EntityTitle>{entity.name}</EntityTitle>
                <DocumentsList>
                  {selectedAccount?.actionRights.documents.map((doc, index) => (
                    <label key={index + doc.id + entity.name} htmlFor={`docID-${entity.name + doc.id}`}>
                      <input
                        checked={isCheckedZipContent(doc, entity)}
                        type='checkbox'
                        name={`doc-${doc.id}`}
                        id={`docID-${entity.name + doc.id}`}
                        onChange={e => toggleZipContent(doc, entity, e.target.checked, 'docx')}
                      />
                      {doc.label}
                    </label>
                  ))}
                  {selectedAccount?.actionRights.registries.map((registry, index) => (
                    <label key={index + registry.id + entity.name} htmlFor={`registryId-${entity.name + registry.id}`}>
                      <input
                        checked={isCheckedZipContent(registry, entity)}
                        type='checkbox'
                        name={`registry-${registry.id}`}
                        id={`registryId-${entity.name + registry.id}`}
                        onChange={e => toggleZipContent(registry, entity, e.target.checked, 'excel')}
                      />
                      {registry.label}
                    </label>
                  ))}
                </DocumentsList>
              </EntityContainer>
            ))}
          </EntitiesContainer>
        )}
        <ActionContainer>
          {(generationMode === GENERATION_MODES.WORD || generationMode === GENERATION_MODES.EXCEL) && (
            <Button
              disabled={!isGenerationReady()}
              onClick={generationMode === GENERATION_MODES.WORD ? handleDocxSubmit : handleExcelSubmit}
              label={'Générer le document'}
            />
          )}
          {/*{generationMode === GENERATION_MODES.ALL && <Button onClick={getCustomZip} label={'Générer le ZIP'} />}*/}
        </ActionContainer>
      </Content>
    </DashboardGenerationContainer>
  );
};

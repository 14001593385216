import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { InputComponent } from '../form/InputComponent';
import { Button } from '../Button';

import {
  removeUserFromAccount,
  selectSelectedAccount,
  selectUser,
  setImpersonated,
  setOriginalUser,
  setSelectedAccount,
  setUser,
  updateAllUsers,
} from '../../reducers/account.reducer';
import { Modal } from '../modal/Modal';
import { useState } from 'react';
import { AdminUserListContainer, UserListBody, UserListContainer, MessageContainer, Separator, ImpersonateUser } from './AdminUserList_.style';
import { setCurrentEntity, setModal } from '../../reducers/app.reducer';
import { paths } from '@routes/routes.constants';
import { useHistory } from 'react-router-dom';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import { UserActionContainer, UserDetailsTop } from '@components/admin-infos/AdminInfos_.style';
import { FaRegSave } from 'react-icons/fa';
import { MODAL_TYPES } from '@utils/constants';
import { RiAddLine } from 'react-icons/ri';

export const AdminUserList = () => {
  const selectedAccount = useSelector(selectSelectedAccount);
  const originalUser = useSelector(selectUser);
  const dispatch = useDispatch<any>();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const handleAddUser = async () => {
    await dispatch(setModal({ show: true, type: MODAL_TYPES.ADD_USER }));
  };

  const handleUpdateAllUsers = async () => {
    dispatch(updateAllUsers({ accountId: selectedAccount._id, users: selectedAccount.users }));
    toast.success(`les infos des utilisateurs ont bien été mis à jour !`);
  };

  const handleUserInfosChange = async (e, user, isCheckBox: boolean = false) => {
    const accountCopy = _.cloneDeep(selectedAccount);
    accountCopy.users.find(userItem => userItem._id === user._id)[e.target.name] = isCheckBox ? e.target.checked : e.target.value;
    await dispatch(setSelectedAccount(accountCopy));
  };

  const handleAccountOfficerChange = async (e, user) => {
    const accountCopy = _.cloneDeep(selectedAccount);
    accountCopy.users.forEach(userItem => (userItem.role = userItem._id === user._id ? 'accountOfficer' : 'admin'));
    await dispatch(setSelectedAccount(accountCopy));
  };

  const handleDeleteUser = async user => {
    if (user.role === 'accountOfficer') {
      setShowModal(true);
      return;
    }
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer l'utilisateur ${user.name} ?`) === true) {
      dispatch(removeUserFromAccount({ userId: user._id }));
      toast.info(`L'utilisateur ${user?.name} a été supprimer !`);
    }
  };

  const impersonateUser = async user => {
    dispatch(setOriginalUser(originalUser));
    dispatch(setUser(user));
    dispatch(setImpersonated(selectedAccount));
    dispatch(setCurrentEntity(null));
    history.push(paths.dashboardGroup);
  };

  const renderUserList = (users, admin = false) => {
    return users.map((user, index) => {
      return (
        <React.Fragment key={`user-${index}`}>
          <div>{index + 1}</div>
          <InputComponent
            type={'text'}
            name={'firstName'}
            key={`firstName-${index}`}
            onChange={e => handleUserInfosChange(e, user)}
            value={user?.firstName}
          />
          <InputComponent type={'text'} name={'name'} key={`userName-${index}`} onChange={e => handleUserInfosChange(e, user)} value={user?.name} />

          <InputComponent
            type={'text'}
            name={'email'}
            key={`userEmail-${index}`}
            onChange={e => handleUserInfosChange(e, user)}
            value={user?.email}
          />

          <div>
            {admin && (
              <label style={{ marginTop: '10px' }} htmlFor={`emailCheckbox-${index}`}>
                <input
                  defaultChecked={user?.receivesEmails}
                  id={`emailCheckbox-${index}`}
                  name={'receivesEmails'}
                  type='checkbox'
                  onChange={e => handleUserInfosChange(e, user, true)}
                />
              </label>
            )}
          </div>
          <div>
            {admin && (
              <label style={{ marginTop: '10px' }} htmlFor={`accountOfficerCheckbox-${index}`}>
                <input
                  defaultChecked={user?.role === 'accountOfficer'}
                  id={`accountOfficerCheckbox-${index}`}
                  name={'accountOfficer'}
                  type='radio'
                  onChange={e => handleAccountOfficerChange(e, user)}
                />
              </label>
            )}
          </div>
          <Button label={'Supprimer'} type={'danger'} onClick={() => handleDeleteUser(user)} />
          <ImpersonateUser>
            <AiOutlineUserSwitch onClick={() => impersonateUser(user)} />
          </ImpersonateUser>
        </React.Fragment>
      );
    });
  };

  const renderHeader = (admin = false) => (
    <>
      <span>N°</span>
      <span>Prénom</span>
      <span>Nom</span>
      <span>Email</span>
      <span>{admin && 'Reçoit les emails'}</span>
      <span>{admin && 'Référent RGPD'}</span>
      <span>Actions</span>
      <span>impersonate</span>
    </>
  );

  const getUserList = () => {
    let adminUsers = selectedAccount.users.filter(user => user.role === 'admin' || user.role === 'accountOfficer');
    let guest = selectedAccount.users.filter(user => user.role === 'guest');
    return (
      <>
        <Separator>Réferent & Administrateurs</Separator>
        {renderHeader(true)}
        {renderUserList(adminUsers, true)}
        {guest.length > 0 && (
          <>
            <Separator>Collaborateurs invités via le partage</Separator>
            {renderHeader()}
            {renderUserList(guest)}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <UserDetailsTop>
        <UserActionContainer>
          <Button label={'Ajouter un administrateur'} icon={RiAddLine} onClick={handleAddUser} />
          <Button label={'sauvegarder les modifications'} icon={FaRegSave} onClick={handleUpdateAllUsers} type={'success'} />
        </UserActionContainer>
      </UserDetailsTop>
      <AdminUserListContainer>
        <UserListContainer>
          <UserListBody>{getUserList()}</UserListBody>
        </UserListContainer>
        <Modal show={showModal} title={'Attention'} onClose={() => setShowModal(false)}>
          <MessageContainer>
            <div>
              L'utilisateur que vous souhaitez supprimer est le <span>référent RGPD</span> du compte. Veuillez sélectionner un{' '}
              <span>autre responsable RGPD </span>
              avant de supprimer cet utilisateur.
            </div>
          </MessageContainer>
        </Modal>
      </AdminUserListContainer>
    </>
  );
};
